import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject, signal } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import { HttpNoNetworkConnectionError, NetworkService } from 'utils';
import { MonitoringService } from './monitoring.service';
export const RETRY_COUNT = new HttpContextToken<number>(() => 0);

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const monitoringService = inject(MonitoringService);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const networkService = inject(NetworkService);
  const generalHttpCodeErrors = [500, 0];
  const errorMessage = signal<string>(null);

  const MESSAGES = {
    INTERNAL_ERROR: translateService.getTranslation(['server_error']),
    NO_CONNECTION: translateService.getTranslation(['noNetworkText']),
  };

  const intercept: HttpInterceptorFn = (req, next) => {
    return next(req).pipe(
      catchError(error => {
        let hideToastIcon = false;

        if (checkNoNetworkConnection(error)) {
          const retryCount = req.context.get(RETRY_COUNT) || 0;
          if (retryCount === 0) {
            req.context.set(RETRY_COUNT, retryCount + 1);
            return networkService.online$.pipe(switchMap(() => intercept(req, next)));
          }

          errorMessage.set(MESSAGES.NO_CONNECTION);
          hideToastIcon = true;

          error = new HttpNoNetworkConnectionError();

          error.wasCaught = true;
        }
        if (generalHttpCodeErrors.includes(error.status)) {
          errorMessage.set(MESSAGES.INTERNAL_ERROR);
          error.wasCaught = true;
        }

        if (errorMessage()) {
          toastService.add(errorMessage(), false, 3000, null, hideToastIcon);
        }

        monitoringService.log(error);
        // Re-throw the error for handling in the component.
        return throwError(() => error);
      })
    );
  };
  return intercept(req, next);
};

/**
 * Helper function to check if an error is likely due to a network connection issue.
 *
 * @param error The error object to check.
 * @returns `true` if it's likely a network error, `false` otherwise.
 */
function checkNoNetworkConnection(error: unknown): boolean {
  return (
    error instanceof HttpErrorResponse &&
    !error.headers.keys().length &&
    !error.ok &&
    !error.status &&
    !error.error.loaded &&
    !error.error.total
  );
}
