import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { APP_ID, inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  BrowserOptions,
  browserTracingIntegration,
  captureException,
  init,
  replayIntegration,
  setTag,
} from '@sentry/angular';
import { filter, take } from 'rxjs';
import { BRAND_CONFIG } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { BrowserService, CustomError, ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import { release } from './release';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private brand = inject(BRAND_CONFIG).brand;
  private featureFlags = inject(FeatureFlagService);
  private platformId = inject(PLATFORM_ID);
  private browserService = inject(BrowserService);
  private appId = inject(APP_ID, { optional: true });
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);

  constructor() {
    if (isPlatformServer(this.platformId)) return;
    if (this.getEnvironment() === 'local') return;
    this.featureFlags.ready.pipe(filter(Boolean), take(1)).subscribe(() => {
      if (!this.featureFlags.isOn('sentry-settings')) return;
      this.initSentry();
    });
  }

  private initSentry() {
    const environment = this.getEnvironment();
    const { dsn, sampleRate, tracesSampleRate } = this.featureFlags.getFeatureValue<BrowserOptions>(
      'sentry-settings',
      {}
    );
    init({
      dsn,
      sampleRate,
      tracesSampleRate,
      integrations: [browserTracingIntegration(), replayIntegration()],
      tracePropagationTargets: [
        new RegExp(`^${this.config.newMicroServiceEndpoint.replace(/\./g, '\\.').replace(/\//g, '\\/')}`),
      ],
      replaysOnErrorSampleRate: 1.0,
      environment,
      release,
      denyUrls: [
        // Sentry recommendations: https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

        // Our own custom url filters
        /cdn\.cookielaw\.org/i,
        /www\.googletagmanager\.com/i,
        /t\.contentsquare\.net/i,
        /live-chat-azure-prod18\.sprinklr\.com/i,
        /www\.clarity\.ms/i,
      ],
      ignoreErrors: [
        // Sentry recommendations: https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',

        // Our own custom error messages to ignore
        'Unknown Error',
        'Load failed',
        'IFrameMessageHandler',
      ],
      beforeSend: event => {
        if (event.exception?.values?.[0]?.stacktrace?.frames?.some(({ filename }) => filename?.includes('gtm.js'))) {
          return null;
        }
        return event;
      },
    });
    setTag('brand', this.brand);
    setTag('app', this.appId);
  }

  captureException(error: CustomError): void {
    if (typeof error === 'string') {
      console.debug('transforming string to Error', error);
      error = new Error(error);
    }
    if (error instanceof Error) {
      console.debug('capturing error', error);
      captureException(error);
    } else if (error instanceof HttpErrorResponse) {
      if (error.error.error && typeof error.error.error === 'string') {
        console.debug('capturing ms-client HttpErrorResponse', error);
        // This is an error from the yol-digital/ms-client library. We should create a new error to make Sentry happy
        error = new Error(error.error.error, { cause: error });
        captureException(error);
      } else {
        console.debug('capturing HttpErrorResponse', error);
        captureException(error);
      }
    } else {
      console.debug('capturing error never', error);
      captureException(new Error(error));
    }
  }

  private getEnvironment() {
    const origin = this.browserService.getOrigin();
    if (origin.includes('localhost')) return 'local';
    if (origin.includes('.dev.')) return 'development';
    if (origin.includes('prodpreview.')) return 'prodpreview';
    return 'production';
  }
}
