import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_ID, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FrontEndLoggerService } from '@yol-digital/ms-client';
import { BrandService } from 'brand';
import { SentryService } from 'sentry';
import { CustomError, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig, HttpNoNetworkConnectionError } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private http = inject(HttpClient);
  private brandService = inject(BrandService);
  private router = inject(Router);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private appId = inject(APP_ID);
  private sentryService = inject(SentryService);
  private api: FrontEndLoggerService.Api;
  private httpMessagesToSkip: string[] = [
    'identity/selfcare',
    'identity/pos',
    'Http failure response for (unknown url)',
    'Failed to fetch dynamically imported module',
    ': 0 undefined',
  ];
  private httpErrorsToSkip: string[] = [
    'ERR_CUSTOMER_FOUND_LOGIN_REQUIRED',
    'ERR_CREDITCHECK_LIGHT_PRODUCT_ONLY',
    'ERR_INVALID_CREDIT_CHECK_DATA',
    'ERR_CREDITCHECK_BAD_CUSTOMER',
    'ERR_CREDITCHECK_MANUAL_CHECK_REQUIRED',
    'ERR_CREDITCHECK_ALLOWED_SUB_EXCEEDED',
    'ERR_PREPAID_MAX_REACHED',
    'ERR_RECORD_NOT_FOUND',
    'ERR_EMAIL_EXISTS',
    'ERR_INVALID_USERNAME_OR_PASSWORD',
    'ERR_INVALID_SECRET_OR_CODE',
    'ERR_CANNOT_PORT_FROM_SAME_BRAND',
    'ERR_INVALID_TOKEN',
    'ERR_PROVIDER_INFO_NOT_FOUND',
  ];
  private errorNamesToSkip: string[] = ['ChunkLoadError'];

  constructor() {
    this.api = new FrontEndLoggerService.Api(this.config.newMicroServiceEndpoint, this.http);
  }

  public log(error: CustomError) {
    if (this.shouldSkipLog(error)) {
      return;
    }

    this.sentryService.captureException(error);

    if (error instanceof Error) {
      error = this.serializeError(error);
    }

    return this.api.public
      .submitLog({
        appName: `${this.brandService.brand}-${this.appId}`,
        message: {
          url: this.router.url,
          error,
        },
      })
      .subscribe();
  }

  private shouldSkipLog(error: CustomError): boolean {
    return (
      error instanceof HttpNoNetworkConnectionError ||
      (error instanceof HttpErrorResponse &&
        (error.url?.split('/').pop() === 'submit-log' ||
          this.httpErrorsToSkip.some(errorToSkip => error.error?.error?.includes(errorToSkip)))) ||
      ((error instanceof Error || error instanceof HttpErrorResponse) &&
        (this.httpMessagesToSkip.some(messageToSkip => error.message?.includes(messageToSkip)) ||
          this.errorNamesToSkip.some(nameToSkip => error.name?.includes(nameToSkip))))
    );
  }

  private serializeError = ({ message, name, stack }: Error) => ({
    name,
    message,
    stack,
  });
}
